@layer base, primeng, app;
/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
* {
    box-shadow: none !important;
}
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.cf-bg {

    border-bottom: 2px solid #004A96;
    border-top: 2px solid #004A96;
}
.navbar-brand {
    padding: 0px !important;
}
.err {
    color: red;
    font-size: small;
}

.p-calendar {
    display: inline-flex !important;
}
/* Status column */
span.status-text {
    padding: 3px 10px;
    border-radius: 20px;
    background-color: #22c55e;
    color: white;
    font-size: smaller;
}
span.status-text-inactive {
    background-color: #c9c9c9 !important;
}
span.status-text-submitted {
    background-color: #21A0D2 !important;
}
span.status-text-progress {
    background-color: #F39500 !important;
}
input.p-inputtext.p-corner-all.p-state-default.p-widget.ng-star-inserted {
    width: -webkit-fill-available;
}

.pi-chevron-down:before {
    content: "\e902";
    font-size: small;
}

.p-datatable .p-datatable-thead > tr >th {
    background-color: #707070 !important;
}
.p-dropdown , .p-multiselect{
    width: 100% !important;
    border: 1px solid #c8c8c8 !important;
    font-weight: 400 !important;
}
.p-datepicker{
    border: 1px solid #c8c8c8 !important;  
}
.p-multiselect-label .p-corner-all{
    font-weight: 400 !important;
}
 .p-multiselect .p-multiselect-panel {
    right: 0px !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-state-highlight {
    color: black !important;
    background-color: white !important;
}
.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl{
    width: 100%;


    margin-right: auto;
    margin-left: auto;
}

.form-control:focus {
    border-color: #007ad9 !important;
    outline: 0;
    box-shadow:  none;
}

.p-inputtext{
    border-color: #ced4da !important;
}

.p-inputtext:enabled:focus{
    border-color: #007ad9 !important;
    outline: 0;
    
}
.p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 0 0  5px 0 !important;
    text-align: right;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-dialog-header {    
    border: 1px solid #c8c8c8 !important;
    background-color: #f4f4f4 !important;
    color: #333333 !important;
    padding: 1em;
    font-weight: 700;
}
.p-dialog .p-datatable .p-datatable-thead>tr>th {
    background-color: #f4f4f4 !important;
    color: #333333 !important;
    font-weight: 700;
    border: 1px solid #c8c8c8;
}
.p-dialog-content {
    padding-top: 10px !important;
}
.p-dialog-mask {
    background: #00000090;
}


#rateForm form .p-dropdown, .p-multiselect, .p-calendar, .p-inputtext, .p-fileupload {
    height: 2.4rem !important;
}
#rateForm .form-control {    
    border: 1px solid #c8c8c8;
}
#rateForm form .p-dropdown .p-dropdown-label {
    padding: 2px 10px;
}
#rateForm form .p-multiselect .p-multiselect-label {
    display: block;
    padding-top: 5px;
}
#rateForm form input[type=radio] {
    margin-right: 5px;
    margin-top: 3px;
}
#rateForm form input[readonly] {
    background: #e9ecef !important;
    opacity: 1;
}
#rateForm .formDiv .cust-label {
    font-weight: 400 !important;
    /* font-size: medium; */
}

.p-multiselect-panel.p-component.ng-star-inserted{
    border: 1px solid #c8c8c8; 
}

#comment-section .p-accordion-content-wrapper{
    padding-right: 55px;
    padding-left: 0px;
}

@media (max-width:540px) {
    .request-form #shipping-content {
        padding-right: 0px !important;
    }

    .request-form #shipping-detail .p-accordion-content.p-widget-content {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .request-form #shipping-detail .row.col-md-12 {
        margin-right: 0px;
        padding-right: 0px;
        padding-left: 0px;
        margin-left: 0px;
    }

    #shipping-detail .p-accordion-content-wrapper {
        width: 332px;
    }

    #comment-section .p-accordion-content-wrapper {
        padding-right: 0px;
        padding-left: 0px;
    }
}
@media(max-width: 1200px) {
    .cf-bg {
        border-bottom: 2px solid #004A96;
        border-top: 2px solid #004A96;
    }
}

.container {
    max-width: 97vw;
}


@media (width >= 1000) {
    #requestListContainer .p-datatable-wrapper {
        max-height: 64vh !important;
    }
}

.home-bg .p-button-text-icon-left .p-button-icon-left{
    margin-left: 1.5rem !important;
  }

.home-bg .p-button-text-icon-left .p-button-text {
    text-align: left !important;
    margin-left: 2.5rem !important;
}
.p-dropdown, .p-multiselect, .p-autocomplete, .p-autocomplete .p-autocomplete-input, .p-calendar, .p-fileupload-choose, .p-inputtext {
    border-radius: var(--bs-border-radius) !important;
}
th .p-calendar .p-inputtext {
    border-radius: var(--bs-border-radius) !important;
}
.p-calendar .p-inputtext {
    border-radius: var(--bs-border-radius) 0 0 var(--bs-border-radius) !important;
}
.p-calendar .p-datepicker-trigger {
    border-radius: 0 var(--bs-border-radius) var(--bs-border-radius) 0 !important;
}

.p-dropdown-items-wrapper {
    max-height: 20rem !important;
}
.p-dropdown-panel, .p-multiselect .p-multiselect-panel,.p-autocomplete-panel, .p-calendar .p-inputtext, .p-fileupload-choose, .p-checkbox .p-checkbox-box {
    border: 1px solid #c8c8c8 !important;
}
.p-dropdown:focus-within {
    border-color: #007ad9 !important;
}

.p-multiselect:focus-within {
    border-color: #007ad9 !important;
}

.btn-primary {
    border-color: #004A96;
}


.custom-control-input:focus ~ .custom-control-label::before {
     box-shadow: none !important; 
}

.custom-control-label::before {
    top: 0.15rem !important;
}

.p-dropdown .cust-dropdown  {
    width: 100% !important;
    height: 38px !important;
}
.p-datatable-resizable-table>.p-datatable-thead>tr>th, .p-datatable-resizable-table>.p-datatable-tfoot>tr>td, .p-datatable-resizable-table>.p-datatable-tbody>tr>td {
    overflow: hidden;
    white-space: normal !important;
}
.p-datatable .p-datatable-tbody>tr>td {
    padding: 0.5rem 0 0.5rem 1rem !important;
}

#landingPage .p-button .p-button-icon-left {
    margin-left: 0.75rem !important
}
#landingPage .p-button-label {
    flex: 0 1 auto !important;
    margin-left: 1rem !important;
}